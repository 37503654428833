<template>
  <div :class="!isMobileDevice ? 'login-container' : 'login-container-mobile'">
    <div class="top" v-if="!isMobileDevice">
      <img
        src="../../assets/login_register/pc/白色公司logo.png"
        style="width: 272px"
        alt=""
        @click="$router.push('/')"
      />
      <!-- <ul>
        <li @click="$router.push('/migrate')">迁徙记忆</li>
        <li @click="$router.push('/drilling')">大数据分析</li>
        <li class="last" @click="$router.push('/')">台湾文献数据库</li>
        <img
          src="../../assets/login_register/pc/寻根logo.png"
          style="width: 24px"
          alt=""
        />
      </ul> -->
    </div>
    <!-- pc -->
    <div class="form-box" v-if="!isMobileDevice">
      <div class="login-form">
        <img
          src="../../assets/login_register/pc/蓝色公司logo.png"
          alt=""
          class="logo"
        />
        <h4><em>密码登</em>录</h4>
        <el-form ref="loginForm" :model="form" class="form">
          <el-form-item>
            <el-input placeholder="请输入账号" v-model="form.username">
              <img
                src="../../assets/login_register/pc/账户图标.png"
                slot="prefix"
                alt=""
              />
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 20px">
            <el-input
              placeholder="请输入密码"
              v-model="form.password"
              show-password
            >
              <img
                src="../../assets/login_register/pc/锁图标.png"
                slot="prefix"
                alt=""
              />
              <!-- <img
                src="../../assets/login_register/pc/眼睛图标.png"
                slot="suffix"
                alt=""
                style="cursor: pointer"
              /> -->
            </el-input>
          </el-form-item>
          <el-form-item v-if="numberOfLoginError >= 3">
            <el-input v-model="form.vCode" placeholder="请输入验证码">
              <img
                src="../../assets/login_register/pc/锁图标.png"
                slot="prefix"
                alt=""
              />
              <el-image
                slot="suffix"
                :src="vcodeUrl"
                style="height: 100%"
                @click.self="getVcode"
              />
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="form.isRmpsd">记住密码</el-checkbox>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click.native.prevent="handleLogin"
              >登 录</el-button
            >
          </el-form-item>
          <el-form-item style="text-align: right">
            <span
              >还没账号？<a
                href="javascript:;"
                @click="$router.push({ name: 'Register' })"
                style="text-decoration: underline"
                >立即注册</a
              ></span
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- mobile -->
    <div class="form-mobile" v-else>
      <img
        src="../../assets/login_register/mobile/蓝色logo.png"
        style="width: 269px"
        alt=""
      />
      <h4><em>密码登</em>录</h4>
      <el-form ref="loginForm" :model="form" class="form">
        <el-form-item>
          <el-input placeholder="请输入账号" v-model="form.username">
            <img
              src="../../assets/login_register/pc/账户图标.png"
              slot="prefix"
              alt=""
            />
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入密码"
            v-model="form.password"
            show-password
          >
            <img
              src="../../assets/login_register/pc/锁图标.png"
              slot="prefix"
              alt=""
            />
            <!-- <img
              src="../../assets/login_register/pc/眼睛图标.png"
              slot="suffix"
              alt=""
              style="cursor: pointer"
            /> -->
          </el-input>
        </el-form-item>
        <el-form-item v-if="numberOfLoginError >= 3">
          <el-input v-model="form.vCode" placeholder="请输入验证码">
            <img
              src="../../assets/login_register/pc/锁图标.png"
              slot="prefix"
              alt=""
            />
            <el-image
              slot="suffix"
              :src="vcodeUrl"
              style="height: 100%"
              @click.self="getVcode"
            />
          </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 6px">
          <el-checkbox v-model="form.isRmpsd">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click.native.prevent="handleLogin"
            style="width: 100%"
            >登 录</el-button
          >
        </el-form-item>
        <el-form-item style="text-align: right">
          <span
            >还没账号？<a
              href="javascript:;"
              @click="$router.push({ name: 'Register' })"
              style="text-decoration: underline"
              >立即注册</a
            ></span
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { checkUserAccountCount } from "@/api/user.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      form: {},
      loading: false,
      vcodeUrl: "", // 图片验证码
      cookiePass: "",
      timeStamp: null,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    let miniprogram = null;
    wx.miniProgram.getEnv(function (res) {
      if (res.miniprogram) {
        // 向小程序发送消息
        wx.miniProgram.postMessage({ data: { foo: {} } });
        wx.miniProgram.navigateBack({ delta: 2 });
        miniprogram = res.miniprogram;
      }
    });
    this.getVcode();
    this.getCookie();
    this.timeStamp = this.$route.query.timeStamp;
    if (this.timeStamp) {
      if (this.timeStamp >= Date.now()) {
        this.form.username = "TPYanshi001";
        this.form.password = "admin123";
      } else {
        //  this.$router.push("/loseEfficacy");
        this.$router.push("/register");
      }
    }
  },

  computed: {
    ...mapState("user", ["numberOfLoginError"]),
    isMobileDevice() {
      return navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      );
    },
    // isIpadFun() {
    //   var ua = window.navigator.userAgent;
    //   var IsIPad = false;
    //   if (/ipad/i.test(ua)) {
    //     IsIPad = true;
    //   }
    //   // iPad from IOS13
    //   var macApp = ua.match(/Macintosh/i) != null;
    //   if (macApp) {
    //     // need to distinguish between Macbook and iPad
    //     var canvas = document.createElement("canvas");
    //     if (canvas != null) {
    //       var context =
    //         canvas.getContext("webgl") ||
    //         canvas.getContext("experimental-webgl");
    //       if (context) {
    //         var info = context.getExtension("WEBGL_debug_renderer_info");
    //         if (info) {
    //           var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
    //           if (renderer.indexOf("Apple") != -1) IsIPad = true;
    //         }
    //       }
    //     }
    //   }
    //   return IsIPad;
    // },
  },
  methods: {
    // 获取图片验证码
    getVcode() {
      this.vcodeUrl = "/api/app/user/vcode?random=" + Math.random();
    },
    async handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let loginPayload = {};
            if (this.numberOfLoginError >= 3) {
              loginPayload = {
                userName: this.form.username,
                password: this.form.password,
                vCode: this.form.vCode,
                isHasCode: true,
                isRmpsd: this.form.isRmpsd,
              };
            } else {
              loginPayload = {
                userName: this.form.username,
                password: this.form.password,
                isRmpsd: this.form.isRmpsd,
                // vCode: this.form.vCode,
              };
            }
            await this.$store.dispatch("user/login", {
              ...loginPayload,
              loginFromType: this.isMobileDevice ? 2 : 1,
            });
            this.$notify.success({ message: "登录成功！" });
            let path =
              this.$route.query.redirect &&
              this.$route.query.redirect !== "undefined"
                ? this.$route.query.redirect
                : "/robotmigrate";
            this.$router.push(path);
            this.$store.commit("user/SET_ERROR_NUMBER", 0);
            const res = await checkUserAccountCount();
            if (res.pc > 1 || res.h5 > 1) {
              this.$notify.info({
                message: "您的账号在其他设备上有登录！",
                duration: 0,
              });
            }
          } catch (err) {
            if (
              err.response.data.error.details === "1" &&
              this.numberOfLoginError < 3
            ) {
              this.$store.commit("user/SET_ERROR_NUMBER", 3);
            } else {
              this.$store.commit(
                "user/SET_ERROR_NUMBER",
                Number(this.numberOfLoginError) + 1
              );
            }
            this.$message.error(err.response.data.error.message);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      let password = Cookies.get("password");
      const isRmpsd = Cookies.get("isRmpsd");
      // 保存cookie里面的加密后的密码
      this.cookiePass = password === undefined ? "" : password;
      password = password === undefined ? this.form.password : password;
      this.form = {
        username: username === undefined ? this.form.username : username,
        password: password,
        isRmpsd: isRmpsd === undefined ? false : Boolean(isRmpsd),
        vCode: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/login_register/pc/大背景图.jpg");
  background-size: 100% 100%;

  .top {
    width: 100%;
    padding: 20px 120px;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;
      li {
        padding: 5px 40px;
        color: #fff;
        border-right: 1px solid #2864db;
        cursor: pointer;
      }

      .last {
        border-right: none;
      }
    }
  }

  .form-box {
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translateX(-50%);
    border-radius: 21px;
    width: 1300px;
    height: 740px;
    background: url("../../assets/login_register/pc/背景框.png") left;

    .login-form {
      width: 570px;
      height: 695px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 80px 63px 0;
      text-align: center;

      h4 {
        text-align: left;
        margin-top: 70px;
        font-size: 18px;
        color: #2864db;
        font-weight: 500;
        padding-bottom: 15px;
        em {
          font-style: normal;
          padding-bottom: 15px;
          border-bottom: 2px solid #2864db;
        }
      }

      .form {
        margin-top: 33px;
        .el-form-item {
          text-align: left;

          span {
            font-size: 16px;
            font-weight: 600;

            a {
              font-style: normal;
              color: #799ee9;
              cursor: pointer;
            }
          }
        }
        ::v-deep .el-input__inner {
          height: 60px;
          border-radius: 10px;
          padding-left: 50px;
        }
        ::v-deep .el-input__prefix {
          display: flex;
          align-items: center;
          margin-left: 18px;
        }
        ::v-deep .el-input__suffix,
        .el-input__suffix-inner {
          display: flex;
          align-items: center;
        }
        ::v-deep .el-input .el-input__clear {
          font-size: 22px;
        }
        ::v-deep .el-input__suffix-inner {
          display: flex;
        }
        ::v-deep .el-button {
          width: 100%;
          border-radius: 10px;
          height: 60px;
          font-size: 18px;
        }
      }
    }
  }
}

//@media screen and ( max-width: 750px) {
.login-container-mobile {
  padding-top: 111px;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/login_register/mobile/背景图.jpg");
  background-size: 100% 100%;

  .form-mobile {
    margin: 0 auto;
    width: 344px;
    height: 492px;
    //background: url("../../assets/login_register/mobile/背景框.png");
    background: #fff;
    background-size: 100%;
    padding: 10px 41px;
    border-radius: 10px;

    h4 {
      display: inline-block;
      margin-top: 22px;
      font-size: 18px;
      color: #2864db;
      em {
        font-style: normal;
        padding-bottom: 6px;
        border-bottom: 2px solid #2864db;
      }
    }

    .form {
      margin-top: 23px;

      ::v-deep .el-form-item {
        margin-bottom: 18px;
      }

      ::v-deep .el-input__prefix {
        display: flex;
        align-items: center;
      }
      ::v-deep .el-input__suffix-inner {
        display: flex;
      }
      ::v-deep .el-input__suffix,
      .el-input__suffix-inner {
        display: flex;
        align-items: center;
      }
    }
  }
}
//}
</style>
